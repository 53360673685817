<template>
  <div class="prd-navigation">
    <v-list dense nav>
      <the-admin-nav v-if="$isUrlScope('/admin')"></the-admin-nav>
      <the-courses-nav v-if="$isUrlScope('/trainer')"></the-courses-nav>
    </v-list>
  </div>
</template>

<script>
export default {
  components: {
    TheAdminNav: () => import("@/modules/cardioguard-admin/components/layout/NavAdmin"),
    TheCoursesNav: () => import("@/modules/cardioguard-trainer/components/layout/NavTrainer"),
  },
};
</script>

<style lang="scss" scoped>
.v-list-item {
  margin-top: 2px;
  font-family: "Lora", serif;
  &--active {
    overflow: visible !important;
    color: #00bba7 !important;
  }
  &--active:before {
    opacity: 0.18 !important;
    background: transparent;
    box-shadow: 3px 3px 3px black !important;
  }
}
.rotate {
  transform: rotate(270deg);
}
</style>
